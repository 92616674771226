import React from 'react';

import Layout from '@layouts';
import { Banners, SEO, EducationFund } from '@components';
import EducationFundVideo from '../popups/EducationFundVideo';

const { EducationFoundBanner } = Banners;

const Index = () => (
  <Layout
    bannerStyle={{ backgroundImage: 'none' }}
    bannerChildren={<EducationFoundBanner title='TAIK Education Fund' />}
    heroBackgroundColor='altlightest'
  >
    <SEO title='TAIK EDUCATION FUND' description="Learn More about TAIK's Education Fund" />
    <EducationFund />
    <EducationFundVideo vidURL='https://youtu.be/pLGkkunhU1Y' />
  </Layout>
);

export default Index;
